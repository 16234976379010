import { Link, useLocation } from "react-router-dom";
import logo from "./images/new_logo.png";

const Header = () => {
  const path = useLocation();

  const handleDone = () => {
    var clist = document.getElementsByClassName("closeCheckbox");
    clist[0].checked = false;
  };

  return (
    <div
      className="header-container"
      style={
        path.pathname !== "/" && path.pathname !== "/rent"
          ? { position: "relative" }
          : {}
      }
    >
      <div
        className="header"
        style={
          path.pathname !== "/" && path.pathname !== "/rent"
            ? {
                backgroundColor: "rgb(18, 31, 41)",
              }
            : {}
        }
      >
        <div className="header-logo">
          <Link to="/">
            <img
              src={logo}
              alt={
                <h1 className="logo-style sacramento-regular">Fredriksborg</h1>
              }
            />
            <span className="logo-style-tag merriweather-regular">
              Bygdøys storstue
            </span>
          </Link>
        </div>
        <div className="header-nav">
          <div style={{ display: "flex" }}>
            <label className="hamburger-menu menu-image">
              <input
                type="checkbox"
                id="closeCheckbox"
                className={"closeCheckbox"}
              />
            </label>
            <ul className="raleway-font nav-list">
              <li className="nav-item">
                <Link
                  to="/"
                  className="link-style"
                  onClick={() => handleDone()}
                >
                  Hjem
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  to="/rent"
                  className="link-style"
                  onClick={() => handleDone()}
                >
                  Leie
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  className="link-style"
                  to="/location"
                  onClick={() => handleDone()}
                >
                  Plassering
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  className="link-style"
                  to="/history"
                  onClick={() => handleDone()}
                >
                  Om Fredriksborg
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Header;
