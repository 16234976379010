import React from "react";
import imgOne from "./images/history/Gamle-Fredriksborg.jpg";
import { Link } from "react-router-dom";

const HistoryHome = () => {
  return (
    <div className="homeHistory-container raleway-style">
      <div className="homeHistory-box">
        <div className="homeHistory-heading">
          <h2>Fredriksborgs Historie </h2>
        </div>
        <div className="homeHistory-content">
          <div className="homeHistory-pic">
            <img src={imgOne} alt="history.img" />
          </div>
          <div
            className="event-content"
            style={{ flexShrink: "1", gap: "20px" }}
          >
            <span>
              Fredriksborg er en eiendom med en broket historie. Det var i den
              innerste delen av Langviksbukten hvor de første beboere på Bygdøy
              slo seg ned. Den gang kalte man øya for Bygdø—som betyr den
              bebodde ø. Langvik er et typisk navn fra jernalderen, slik også
              Hengsengen er. De første som slo seg ned her var fiskerfamilier og
              etter hvert – tidlig på 1800 tallet – ble dette et fristed for
              litt løssluppen virksomhet. Per Chr. Asbjørnsen (vår
              eventyrbokforfatter) skrev i 1843 en erindringsfortelling om en
              aften i Langvik og på Fredriksborg. Mesteparten av denne artikkel
              er gjengitt i den første jubileumsboken til Bygdø Vel fra 1927.
              <br />
              For Bygdøys befolkning har gaven fra familien Kloster vært
              uvurderlig. Det å ha et slikt bygg til disposisjon for en menighet
              er helt uvurderlig. En stor takk til familien Kloster.
            </span>
            <button>
              <Link to="/history">MORE INFO </Link>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HistoryHome;
