import WhatWeDo from "./WhatWeDo";
import MakeMemories from "./MakeMemories";
import Testimonial from "./Testimonial";
import Video from "./Video";
import HistoryHome from "./HistoryHome";
const Home = () => {
  return (
    <div>
      <Video />
      <HistoryHome />
      <WhatWeDo />
      <MakeMemories />
      <Testimonial />
    </div>
  );
};

export default Home;
