import React from "react";
import video from "url:../src/images/Fredriksborg15sek.mp4";
import thumbnail from "../src/images/building-images/building-img.jpeg";
import { Link } from "react-router-dom";

const Video = () => {
  return (
    <div className="video-container">
      <div className="video-box">
        <div className="bg-video">
          <video
            autoPlay
            loop
            muted={true}
            playsInline
            preload="none"
            playwheninactive="false"
            src={video}
            style={{
              cursor: "auto",
              width: "100%",
              height: "100%",
              borderRadius: "0px",
              display: "block",
              objectFit: "cover",
              backgroundColor: "rgba(0, 0, 0, 0)",
              objectPosition: "50% 50%",
            }}
            poster={thumbnail}
          ></video>
          <div className="opacity-style"></div>
        </div>
        <div className="video-text">
          <div className="in-video-text">
            <h5
              className="merriweather-bold"
              style={{ fontSize: " 28px", fontWeight: "300" }}
            >
              <Link className="border-btm-poster" to="/rent">
                Stedet for dine små og store anledninger
              </Link>
            </h5>
            <h6
              className="merriweather-regular"
              style={{ fontSize: "18px", textDecoration: "none" }}
            >
              <Link
                style={{ textDecoration: "none", color: "#121f29" }}
                to="/rent"
              >
                Planlegg besøket ditt her
              </Link>
            </h6>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Video;
