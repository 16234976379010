const WhatWeDo = () => {
  return (
    <div className="whatWeDo-container">
      <div className="whatWeDo-box">
        <div className="event-wrap">
          <div className="whatWeDo-heading">
            <h2>Hva vi gjør </h2>
          </div>
          <div className="event-list">
            <div className="event-box">
              <div className="event-title raleway-font">
                <h3 style={{ fontWeight: "600" }}>Leie av Fredriksborg</h3>
              </div>
              <div className="event-content raleway-style ">
                Fredriksborg er tilgjengelig for utleie til større og mindre
                arrangementer. Skal man feire bryllup, dåp, runde dager – eller
                minnes et kjært familiemedlem – er Fredriksborg en vakker ramme
                om arrangementet. De tre store salongene er holdt i klassisk
                stil som det høver seg en slik bygning.
              </div>
            </div>
            <div className="event-box">
              <div className="event-title raleway-font">
                <h3 style={{ fontWeight: "600" }}>Servering av alkohol</h3>
              </div>
              <div className="event-content raleway-style ">
                Fredriksborg har ikke egen skjenkebevilling. Derfor kan man ta
                med alkoholholdig drikke for bruk i selskapet så lenge det er et
                privat selskap. Men hvis man skal selge alkoholholdige
                drikkevarer må man søke om såkalt "Skjenkebevilgning for åpent
                og lukket arrangement” hos Næringsetaten.
              </div>
            </div>
          </div>
        </div>
        <div className="whatWeDo-pic">
          <div
            style={{
              position: "relative",
              borderRadius: "inherit",
              inset: "0px",
              width: "100%",
              height: "100%",
            }}
          >
            <div
              style={{
                display: "contents",
                borderRadius: "inherit",
                width: "100%",
                height: "100%",
                position: "absolute",
              }}
            >
              <img
                src="https://fredriksborg.bybe.no/wp-content/uploads/2017/03/Fredriksborg-1.jpg"
                alt="img"
                style={{
                  display: " block",
                  width: "100%",
                  height: "100%",
                  borderRadius: "inherit",
                  objectPosition: "center center",
                  objectFit: "cover",
                  imageRendering: "auto",
                  height: "100%",
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WhatWeDo;
