import React from "react";
import imgOne from "./images/history/Gamle-Fredriksborg.jpg";
import imgTwo from "./images/history/Luisebryggen.jpg";
import imgThree from "./images/history/Skipsreder-Lauritz-Kloster.jpg";
import imgFour from "./images/history/Fru-Kloster.jpg";

const History = () => {
  return (
    <div className="History-container raleway-style">
      <div className="History-box">
        <div className="History-box-top">
          <h3 className="History-heading">FREDRIKSBORGS HISTORIE</h3>
          <div className="History-text">
            Fredriksborg er en eiendom med en broket historie. Det var i den
            innerste delen av Langviksbukten hvor de første beboere på Bygdøy
            slo seg ned. Den gang kalte man øya for Bygdø—som betyr den bebodde
            ø. Langvik er et typisk navn fra jernalderen, slik også Hengsengen
            er. De første som slo seg ned her var fiskerfamilier og etter hvert
            – tidlig på 1800 tallet – ble dette et fristed for litt løssluppen
            virksomhet. Per Chr. Asbjørnsen (vår eventyrbokforfatter) skrev i
            1843 en erindringsfortelling om en aften i Langvik og på
            Fredriksborg. Mesteparten av denne artikkel er gjengitt i den første
            jubileumsboken til Bygdø Vel fra 1927.
          </div>
        </div>
        <div className="History-box-bottom">
          <div className="History-section">
            <h3 className="History-heading">LIVLIG UTESTED</h3>
            <div className="History-text">
              <span className="image-text-style">
                <img src={imgOne} />
                Det gamle Fredriksborg går langt tilbake i historien, men det
                var først rundt 1830 at stedet oppstod som et forlystelsessted
                med en viss stil. De første årene med et nokså tvilsomt rykte.
                Her var det piker, vin og sang. Henrik Groth (forleggeren)
                påstår i sin beretning om Bygdøy at det angivelig var
                bordellvirksomhet på Fredriksborg i gamle dager. Trolig var det
                Langvik-Hansen, som også eide restauranten ”Løven” i Møllergaten
                som stod bak driften av det hele. Men dette må ha vært i tiden
                før man fikk fast fergeforbindelse med byen. Fredriksborg er
                også omtalt i Christian Kroghs “Kampen for tilværelsen”.
                Albertine – som da var en del av Kristiaias “lette garde” dro ut
                hit sammen med kolleger for å skaffe kunder.
              </span>
              <br />
              Noen av de første arbeidermøter som Marcus Thrane holdt var på
              Fredriksborg i 1850. Thrane var barnebarn til Paul Thrane som i
              sin tid eide Rodeløkken før den ble solgt til kong Karl Johan i
              1819.
              <br />
              I 1852 ble den første ballongferd i Norge foretatt fra
              Fredriksborg. Den er beskrevet i Oslo Filatelitsklubbs blad fra
              2016 <br />I 1864 ble fast fergeforbindelse med dampbåten Louise
              mellom Pipervigen og Fredriksborg brygge og det gjorde ikke stedet
              mindre attraktivt å reise ut til.
              <br />
              <span className="image-text-style">
                I 1880 overtok den svenske skuespiller og direktør Knut Tivander
                stedet og ga det navnet Fredriksborg Tivoli, og nå ble det et
                populært utfartssted for Christiania borgere. Her var det
                restaurant, karusell, skytebane og flere severdigheter som ikke
                gjorde skam på tivoli navnet. Tivander holdt ikke på i mange år.
                Allerede i 1888 er det Kristiania Bryggeris Interessentskap som
                står som eier. <img src={imgTwo} />
              </span>
              <br />
              Sommerrevyene på Fredriksborg Tivoli var populære. Stedet hadde,
              som tidligere nevnt, to scener og det var både ballett, høytlesing
              og sang på programmet. Sommeren 1897 etablerte man Fredriksborg
              Sommertheater og viste revyen ”Fra Vika til Ekebergtoppen”. Den
              handlet om Kal Olsen fra Vika – en figur som ble skapt av redaktør
              og kapellmester Theodor Løvstad (han i Bekkebukten) og gikk som
              fast innslag i Vikingen (vittighetsblad) der Løvstad var redaktør.
            </div>
          </div>
          <div className="History-section">
            <h3 className="History-heading">FREDRIKSBORG BRENNER</h3>
            <div className="History-text">
              Sommeren 1897 oppstår det en dramatisk brann i den østre delen av
              Fredriksborg. Det skjedde under forestillingen om Kal Olsen og
              huset var stappfullt. Heldigvis gikk det greit og ingen personer
              kom til skade.
              <br />
              Den siste som drev Fredriksborg som forlystelsessted var dir. Karl
              A. Pedersen.
              <br />
              I 1916 ble Christiania Film Co. AS etablert av Petter Lykke-Seest
              og han etablerte et filmstudio på Fredriksborg. Den første filmen
              som ble laget der het «Unge hjerter» og den hadde premiere i 1917.
              <br />
              Løkkeeierne på Bygdøy var ikke spesielt begeistret for stedet.
              Særlig søndag middagstid og utover natten var det stor trafikk
              der. Det ble spilt «Hompamusikk» i ett kjør fra dansesalen.
              Spetakkelet fra frilufts forlystelser, særlig da fra maskinen for
              «styrkeprøve», hvor sterke karer ustanselig slo den svære klubben
              i bånn for å drive flymerket til topps.
              <br />
              Hoing og skrål fra mer eller mindre berusede gjester drev omkring
              på veiene rundt Fredriksborg.
              <br />
              Naboer besluttet å kjøpe stedet for så å rive det. Noe som ble
              gjort.
            </div>
          </div>
          <div className="History-section">
            <h3 className="History-heading">VILLA FREDRIKSBORG</h3>
            <div className="History-text">
              <span className="image-text-style">
                <img src={imgThree} />I 1917 kjøpte skipsreder Laurits Kloster
                eiendom og rev det som var igjen av bygninger. Kloster hadde
                dette året gjort en «kule» i shipping markedet og bestemte seg
                for å flytte fra Stavanger til Christiania. Kloster engasjerte
                arkitektfirmaet Berner & Berner, og Carl Berner (sønn av
                tidligere Stortingspresident Carl Berner) fikk i oppdrag å tegne
                huset, som fikk navnet Villa Fredriksborg, og bygget stod ferdig
                i 1920. Huset var både bolig for Klosterfamilien og kontor for
                rederiet.
              </span>
              <br />
              <span className="image-text-style">
                Ekteparet Kloster var sterkt samfunnsengasjerte mennesker som
                var veldig interessert i kirken og menigheten på Bygdøy. Under
                krigen, i 1942, ble Bygdø Kirkeforening stiftet i Villa
                Fredriksborg, og denne forening virket som menighetsråd for
                Bygdøy frem til 1956. Sogneprest i Ullern og senere biskop i
                Oslo Johannes Smith var en nær venn av Klosterfamilien og det
                var gjennom dette vennskapet at fru Ellen Kloster (som hadde
                Villa Fredriksborg som særeie) i et gavebrev fra 1947 ga hele
                eiendommen til Bygdøy menighet. Kloster ble boende på
                Fredriksborg til sin død i 1952
                <img src={imgFour} />
              </span>
              <br />
              Huset egnet seg godt som menighetshus for Bygdøy og ble tatt
              aktivt i bruk fra første stund. I 1956 ble Norsk Kirkeakademi
              etablert på Fredriksborg. 170 personer møtte frem til dette
              arrangementet.
              <br />
              mai 1958 gikk Bygdø Kapell tapt i brann. Ganske raskt ble det
              etablert regulære gudstjenester på Fredriksborg. Den praktfulle
              spisestuen ble nytt kirkerom og fungerte som kirke for menigheten
              på Bygdøy frem til nytt kirkebygg stod ferdig i 1968.
            </div>
          </div>
          <div className="History-section">
            <h3 className="History-heading">I DAG</h3>
            <div className="History-text">
              I dag fungerer Fredriksborg som menighetshus med en rekke
              aktiviteter for barn og voksne. Hver siste søndag i måneden
              arrangeres det aftengudstjeneste og Onsdagsklubb en har sine møter
              her. I kjelleren drives det en familiebarnehage og annen etasje er
              utleid til hybler.
              <br />
              Fredriksborg er også er veldig populært selskapslokale for
              bryllup, merkedager, minnestunder og andre aktiviteter der man har
              behov for et hyggelig møtelokale.
              <br />
              Eiendommen Fredriksborg har en bygningsmasse som krever et aktivt
              vedlikehold. Driftskostnadene er høye og visse grep har måttet
              gjøres for å sikre tilskudd til driften. Nedre del av den
              tidligere praktfulle hagen er utbygget, noe som gir grei
              avkastning. Likeledes ble det i det gamle posthuset innredet en
              café som leies ut. Den tidligere sjåførbolig og garasje fungerer i
              dag som prestebolig.
              <br />
              Stiftelser som Sap Sapiente og Bergesenstiftelsen har bidratt med
              midler til vedlikehold og modernisering
              <br />
              For Bygdøys befolkning har gaven fra familien Kloster vært
              uvurderlig. Det å ha et slikt bygg til disposisjon for en menighet
              er helt uvurderlig.
              <br />
              En stor takk til familien Kloster.
            </div>
          </div>
          <div className="History-section">
            <h3 className="History-heading">Møt våre styret</h3>
            <div className="History-text">
              <ul>
                <li>
                  <div className="boardName">Isak Kverne (Styreleder)</div>
                </li>
                <li>
                  <div className="boardName">Arne Slørdahl</div>
                </li>
                <li>
                  <div className="boardName">Narve Jordheim</div>
                </li>
                <li>
                  <div className="boardName">Liv Anniken Berg</div>
                </li>
                <li>
                  <div className="boardName">Bård Sandvik</div>
                </li>
                <li>
                  <div className="boardName">Morten Hultman Andresen</div>
                </li>
                <li>
                  <div className="boardName">Jørn Lein Mathiesen</div>
                </li>
                <li>
                  <div className="boardName">Jan Isnes</div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default History;
