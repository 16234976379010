import call from "../src/images/call.svg";
import mail from "../src/images/mail.svg";
import add from "../src/images/add.svg";
import { Link, useLocation } from "react-router-dom";
import logo from "./images/new_logo.png";

const Footer = () => {
  const path = useLocation();
  return (
    <div className="footer-container">
      <div className="footer-box">
        {path.pathname !== "/location" ? (
          <h3
            className="raleway-font"
            style={{ color: "#fff", fontSize: "35px" }}
          >
            TA KONTAKT
          </h3>
        ) : (
          ""
        )}
        {path.pathname !== "/location" ? (
          <div className="footer-box-top">
            <div className="footer-contact raleway-font">
              <div>
                <h3 style={{ fontSize: "25px" }}>Kontakt</h3>
                <span style={{ fontWeight: "700" }}>Marianne Haugen</span>
                <span>
                  <img style={{ padding: "0 5px 0 0" }} alt="call" src={call} />{" "}
                  : <img alt="" src={add} />
                  <Link
                    to="#"
                    style={{ paddingLeft: "0px" }}
                    onClick={(e) => {
                      window.location.href = "tel:+4791357478";
                      e.preventDefault();
                    }}
                  >
                    47 913 57 478
                  </Link>
                </span>
                <span>
                  <img style={{ padding: "0 5px 0 0" }} alt="mail" src={mail} />{" "}
                  :
                  <Link
                    to="#"
                    onClick={(e) => {
                      window.location.href = "mailto:fborg@online.no";
                      e.preventDefault();
                    }}
                  >
                    fborg@online.no
                  </Link>
                </span>
              </div>
            </div>
            <div className="vertical-line"></div>
            <div className="footer-map">
              <iframe
                className="iframeClass"
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d16003.934167128953!2d10.664053743486786!3d59.907385472834264!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x46416c39bae14b79%3A0x11fd06bb76b9fe1d!2sFredriksborg%20selskapslokale!5e0!3m2!1sen!2sno!4v1711797885877!5m2!1sen!2sno"
                allowFullScreen=""
                loading="lazy"
                referrerPolicy="no-referrer-when-downgrade"
              ></iframe>
              <div className="raleway-font map-address">
                Fredriksborgveien 18, 0286, Oslo
              </div>
            </div>
          </div>
        ) : (
          ""
        )}

        <div className="header-logo">
          <Link to="/">
            <img
              src={logo}
              alt={
                <h1 className="logo-style sacramento-regular">Fredriksborg</h1>
              }
            />
          </Link>
        </div>
        <div className="footer-menu">
          <ul className="raleway-font footer-navlist">
            <li className="nav-item">
              <Link to="/" className="link-style">
                Hjem
              </Link>
            </li>
            <li className="nav-item">
              <Link to="/rent" className="link-style">
                Leie
              </Link>
            </li>
            <li className="nav-item">
              <Link className="link-style" to="/location">
                Plassering
              </Link>
            </li>
            <li className="nav-item">
              <Link className="link-style" to="/history">
                Om Fredriksborg
              </Link>
            </li>
          </ul>
        </div>
        <div className="footer-copyright">
          <div>
            Copyright © 2024 Styret for Fredriksborg. All rights reserved.
          </div>
          <div>
            Made with ❤️ by <span> </span>
            <Link
              to="https://www.linkedin.com/in/bhawnamewada/"
              target="_blank"
              rel="noopener noreferrer"
            >
              Bhawna Rajput
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Footer;
