import ReactDOM from "react-dom/client";
import Header from "./src/Header";
import Footer from "./src/Footer";
import Home from "./src/Home";
import Location from "./src/Location";
import History from "./src/History";
import Error from "./src/404page";
import { createHashRouter, RouterProvider, Outlet } from "react-router-dom";
import ScrollToTop from "./src/Utilities/ScrollToTop";
import Rent from "./src/Rent";

const App = () => {
  return (
    <div className="app-container">
      <div className="home-container">
        <ScrollToTop />
        <Header />
        <Outlet />
        <Footer />
      </div>
    </div>
  );
};
const appRouter = createHashRouter([
  {
    path: "/",
    element: <App />,
    children: [
      {
        path: "/",
        element: <Home />,
      },
      {
        path: "/history",
        element: <History />,
      },
      {
        path: "/location",
        element: <Location />,
      },
      {
        path: "/rent",
        element: <Rent />,
      },
    ],
    errorElement: <Error />,
  },
]);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(<RouterProvider router={appRouter} />);
