import astrick from "./images/astrick.svg";
import wave from "./images/wave.svg";
import buildingImg from "./images/building-images/building-img.jpeg";
import buildingImg1 from "./images/building-images/building-img-1.jpeg";
import buildingImg2 from "./images/building-images/building-img-2.jpeg";
import buildingImg6 from "./images/building-images/building-img-6.jpeg";
import dinning1 from "./images/building-images/dinning1.jpeg";
import dinning2 from "./images/building-images/dinning2.jpeg";
import dinning3 from "./images/building-images/dinning3.jpeg";
import dinning4 from "./images/building-images/dinning4.jpeg";
import dinning5 from "./images/building-images/dinning5.jpeg";
import kitchen from "./images/building-images/kitchen.jpeg";
import room1 from "./images/building-images/room1.jpeg";
import room3 from "./images/building-images/room3.jpeg";
import clip from "url:../src/images/celebration_clip.mp4";
import thumbnail from "../src/images/building-images/building-img.jpeg";

const MakeMemories = () => {
  return (
    <div className="MakeMemories-container">
      <div className="makeMemory-box">
        <div className="makeMemory-top">
          <div className="makeMemory-top-left">
            <div className="makeMemory-image-container">
              <section className="section-style">
                <div className="img-wrapper">
                  <div className="img-list">
                    <img src={buildingImg} alt="img.jpg" />
                    <img src={buildingImg1} alt="img.jpg" />
                    <img src={buildingImg2} alt="img.jpg" />
                    <img src={dinning3} alt="img.jpg" />
                    <img src={dinning4} alt="img.jpg" />
                    <img src={dinning5} alt="img.jpg" />
                    <img src={room1} alt="img.jpg" />
                    <img src={room3} alt="img.jpg" />
                    <img src={buildingImg} alt="img.jpg" />
                  </div>
                </div>
              </section>
            </div>
            <div className="image-sticker "></div>
          </div>
          <div className="makeMemory-top-right">
            <div className="astrick-style">
              <img alt="astrick.img" src={astrick} />
            </div>
            <div className="makeMemory-title">
              <h2 className="raleway-style ">
                Lag Minner
                <br />
                på Fredriksborg
              </h2>
            </div>
            <div className="makeMemory-content">
              <p style={{ fontSize: "16px", lineHeight: "140%" }}>
                <span className="raleway-font" style={{ fontWeight: "400" }}>
                  Fredriksborg er Bygdøys storstue og menighetshus. Huset er
                  bygget i 1922 som herskapsbolig for familien Kloster. I 1947
                  donerte familien Kloster stedet til Bygdøy Menighet og har
                  siden fungert også som menighetshus.
                </span>
              </p>
            </div>
            <div className="wave-style">
              <img alt="wave.img" src={wave} />
            </div>
          </div>
        </div>
        <div className="makeMemory-bottom">
          <div className="makeMemory-bottom-left">
            <div className="makeMemory-image-container1">
              <section className="section-style">
                <div className="img-wrapper1">
                  <video
                    autoPlay
                    loop
                    muted
                    playsInline
                    preload="none"
                    src={clip}
                    style={{
                      cursor: "auto",
                      width: "100%",
                      height: "100%",
                      borderRadius: "0px",
                      display: "block",
                      objectFit: "cover",
                      backgroundColor: "rgba(0, 0, 0, 0)",
                      objectPosition: "50% 50%",
                    }}
                    poster={thumbnail}
                  ></video>
                </div>
              </section>
            </div>
            <div className="image-sticker1 "></div>
          </div>
          <div className="makeMemory-bottom-right">
            <div className="makeMemory-image-container2">
              <section className="section-style">
                <div className="img-wrapper2">
                  <div className="img-list2">
                    <img src={buildingImg6} alt="img.jpg" />
                    <img src={dinning1} alt="img.jpg" />
                    <img src={dinning2} alt="img.jpg" />
                    <img src={kitchen} alt="img.jpg" />
                    <img src={buildingImg6} alt="img.jpg" />
                  </div>
                </div>
              </section>
            </div>
            <div className="image-sticker2 "></div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default MakeMemories;
