import React from "react";
import location from "./images/location.svg";

const Location = () => {
  return (
    <div className="contact-container">
      <div className="contact-text">
        Fredriksborg ligger i Oslo sentrum ved Vikingskipene.
      </div>

      <div className="map-box">
        <div className="contact-heading">Slik kommer du til Fredriksborg</div>
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d8003.01803289027!2d10.669068074441928!3d59.90302457106914!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x46416c39bae14b79%3A0x11fd06bb76b9fe1d!2sFredriksborg%20selskapslokale!5e0!3m2!1sen!2sno!4v1711995877557!5m2!1sen!2sno"
          className="contact-map"
          allowFullScreen=""
          loading="lazy"
          referrerPolicy="no-referrer-when-downgrade"
        ></iframe>
        <div
          className="contact-transport transport-display"
          style={{ fontSize: "18px", fontWeight: "600" }}
        >
          <img src={location} alt="loaction" style={{ width: "22px" }} />{" "}
          Fredriksborgveien 18, 0286, Oslo
        </div>
        <div
          className="contact-transport"
          style={{ gap: "20px", padding: "30px 0" }}
        >
          <div style={{ fontSize: "22px", fontWeight: "600" }}>
            Kollektivtransport
          </div>
          <div
            style={{
              fontSize: "18px",
              fontWeight: "400",
              display: "flex",
              gap: "5px",
            }}
          >
            <div
              style={{
                border: " 3px solid #b58c67 ",
                height: "2px ",
                alignSelf: "center",
              }}
            ></div>
            Med buss 30 retning Bygdøy – stoppested “Fredriksborg” enkelt og
            greit.
          </div>
        </div>
      </div>
    </div>
  );
};

export default Location;
