import quotes from "../src/images/quotes.svg";
const Testimonial = () => {
  return (
    <div className="Testmonial-container">
      <div className="Testmonial-box">
        <div className="Testmonial-title raleway-style">
          <h2>Hva folk sier</h2>
          <h3> Ravne Anmeldelse</h3>
        </div>
        <div className="testimonial-reviewBox">
          <section className="testimonial-review-section">
            <ul className="review-list raleway-style">
              <li>
                <div className="list-item">
                  <img src={quotes} />
                  <div>
                    Elegant og hyggelig lokalet med fin interiør og uteområdet.
                    Vurderer å leie til en arrangement 🥰
                  </div>
                  <p>Joanna M. Szybiak</p>
                </div>
              </li>
              <li>
                <div className="list-item">
                  <img src={quotes} />
                  <div>
                    Meget pent bygg på Bygdøy. Her er det også plass for å
                    parkere noen biler. Ikke så veldig langt fra deler av
                    museene på Bygdøy. Dette bygget har også en historisk verdi
                    i Oslo.
                  </div>
                  <p>Gøran Arnesen</p>
                </div>
              </li>
              <li>
                <div className="list-item">
                  <img src={quotes} />
                  <div>Bra og pent, fornuftige priser</div>
                  <p>Thomas Berntsen </p>
                </div>
              </li>
              <li>
                <div className="list-item">
                  <img src={quotes} />
                  <div>
                    Flott sted med særegen atmosfære som egner seg veldig godt
                    til kveldsgudstjenester siste søndag hver måned.
                  </div>
                  <p>Heidi Park</p>
                </div>
              </li>
              <li>
                <div className="list-item">
                  <img src={quotes} />
                  <div>
                    Pompøst og unødvendig, men bra for slike folk. Godt med
                    plass og kjapp tilgang på toalett
                  </div>
                  <p>Elias Fure </p>
                </div>
              </li>
              <li>
                <div className="list-item">
                  <img src={quotes} />
                  <div>
                    Veldig flotte lokaler, enkle å samarbeide med. Brukt til
                    bryllup.
                  </div>
                  <p>Lasse Østervold </p>
                </div>
              </li>
              <li>
                <div className="list-item">
                  <img src={quotes} />
                  <div>Hyggelig selskapslokale i tradisjonell stil.</div>
                  <p> Hans Magnus Borge </p>
                </div>
              </li>
              <li>
                <div className="list-item">
                  <img src={quotes} />
                  <div>
                    Mange gode og hyggelige minner fra Fredriksborg. Har alt man
                    behøver.
                  </div>
                  <p>Hege Bruvoll </p>
                </div>
              </li>
              <li>
                <div className="list-item">
                  <img src={quotes} />
                  <div>
                    Elegant og hyggelig lokalet med fin interiør og uteområdet.
                    Vurderer å leie til en arrangement 🥰
                  </div>
                  <p>Joanna M. Szybiak</p>
                </div>
              </li>
              <li>
                <div className="list-item">
                  <img src={quotes} />
                  <div>
                    Meget pent bygg på Bygdøy. Her er det også plass for å
                    parkere noen biler. Ikke så veldig langt fra deler av
                    museene på Bygdøy. Dette bygget har også en historisk verdi
                    i Oslo.
                  </div>
                  <p>Gøran Arnesen</p>
                </div>
              </li>
              <li>
                <div className="list-item">
                  <img src={quotes} />
                  <div>Bra og pent, fornuftige priser</div>
                  <p>Thomas Berntsen </p>
                </div>
              </li>
              <li>
                <div className="list-item">
                  <img src={quotes} />
                  <div>
                    Flott sted med særegen atmosfære som egner seg veldig godt
                    til kveldsgudstjenester siste søndag hver måned.
                  </div>
                  <p>Heidi Park</p>
                </div>
              </li>
              <li>
                <div className="list-item">
                  <img src={quotes} />
                  <div>
                    Pompøst og unødvendig, men bra for slike folk. Godt med
                    plass og kjapp tilgang på toalett
                  </div>
                  <p>Elias Fure </p>
                </div>
              </li>

              <li>
                <div className="list-item">
                  <img src={quotes} />
                  <div>
                    Veldig flotte lokaler, enkle å samarbeide med. Brukt til
                    bryllup.
                  </div>
                  <p>Lasse Østervold </p>
                </div>
              </li>
              <li>
                <div className="list-item">
                  <img src={quotes} />
                  <div>Hyggelig selskapslokale i tradisjonell stil.</div>
                  <p> Hans Magnus Borge </p>
                </div>
              </li>
              <li>
                <div className="list-item">
                  <img src={quotes} />
                  <div>
                    Mange gode og hyggelige minner fra Fredriksborg. Har alt man
                    behøver.
                  </div>
                  <p>Hege Bruvoll</p>
                </div>
              </li>
            </ul>
          </section>
        </div>
      </div>
    </div>
  );
};

export default Testimonial;
